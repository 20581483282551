import React, { PointerEvent, useState } from 'react';
import { useEditor } from '@pages/smartGrid/store/hooks';
import {
    DndContext,
    DragEndEvent,
    DragOverEvent,
    DragOverlay,
    DragStartEvent,
    PointerSensor,
    pointerWithin,
    UniqueIdentifier,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { useSnackbar } from 'notistack';

import {
    OverlayPreview,
    OverlayPreviewProps as ActivePlugin,
} from '@pages/smartGrid/components/shared/OverlayPreview/OverlayPreview';
import { styles as s } from './DndWrapper.css';
import { GridView } from '@pages/smartGrid/components/GridView';
import { GridTools } from '@pages/smartGrid/components/GridTools';
import { createPortal } from 'react-dom';
import { RenderView } from '@pages/smartGrid/components/GridView/GridView';
import {
    checkIsAcceptable,
    checkIsCustomPlacingAcceptable,
    createParentId,
    getCommonDraggingParts,
    getDraggingElement,
    getOverElementParent,
    isElementBetweenStackCheck,
    isExistingInsideCurrentContainerCheck,
    isParentMainCheck,
    isTheSameParentCheck,
    putElementAtCustomPosition,
} from './util';

export interface DndWrapperProps {}

const NO_DRAGGABLE_ID = 'not_draggable__container';
const GUTTER_ID = 'not_draggable__gutter';

export class SmartPointerSensor extends PointerSensor {
    static activators = [
        {
            eventName: 'onPointerDown' as any,
            handler: ({
                nativeEvent: event,
            }: PointerEvent) => {
                if (
                    !event.isPrimary ||
                    event.button !== 0 ||
                    isInteractiveElement(
                        event.target as Element,
                    )
                ) {
                    return false;
                }

                return true;
            },
        },
    ];
}

function isInteractiveElement(element: Element | null) {
    const noDraggableNode =
        document.getElementById(NO_DRAGGABLE_ID);

    const isNotDraggable =
        noDraggableNode?.contains(element) ||
        element?.id === NO_DRAGGABLE_ID ||
        element?.id === GUTTER_ID;

    const interactiveElements = [
        'button',
        'input',
        'textarea',
        'svg',
        'path',
        'select',
        'option',
    ];

    if (
        (element?.tagName &&
            interactiveElements.includes(
                element.tagName.toLowerCase(),
            )) ||
        isNotDraggable
    ) {
        return true;
    }

    return false;
}

export const DndWrapper: React.FC<any> = () => {
    const {
        smartGridEditor,
        handleMoveElement,
        handleCreateElement,
        handleCreateElementAtCustomPosition,
        handleRemoveElement,
        getTemplate,
        getCurrentDataElement,
        getParent,
        getCurrentState,
        handleUpdateElement,
        potokus,
    } = useEditor();

    const { enqueueSnackbar } = useSnackbar();

    const [activeId, setActiveId] =
        useState<UniqueIdentifier | null>(null);
    const [overId, setOverId] =
        useState<UniqueIdentifier | null>(null);
    const [activePlugin, setActivePlugin] =
        useState<ActivePlugin | null>(null);

    const pointerSensor = useSensor(SmartPointerSensor, {
        activationConstraint: {
            distance: 15,
        },
    });
    const sensors = useSensors(pointerSensor);

    const handleDragEnd = (e: DragEndEvent) => {
        const overId = e.over?.id;
        const data = e.active.data.current;

        if (!data) return;

        const { id, parentId, type, meta } = data;

        const { draggingElement, draggingElementParent } =
            getDraggingElement({
                id,
                type: type,
                getCurrentDataElement,
            });

        if (type === 'POTOKUS' && potokus) {
            enqueueSnackbar(
                'Потокус уже присутствует в сетке',
                {
                    variant: 'error',
                },
            );
            return;
        }

        const {
            template,
            isContainer,
            isMain,
            overElement,
        } = getCommonDraggingParts({
            draggingElement,
            overId,
            type,
            getCurrentDataElement,
            getTemplate,
            getParent,
        });

        const coreParent = createParentId({
            type,
            overId,
            isContainer,
            getCurrentDataElement,
            getCurrentState,
            handleUpdateElement,
        });

        const overElementParent = getOverElementParent({
            overElement,
            getCurrentDataElement,
        });

        const isTheSameParent = isTheSameParentCheck({
            type,
            draggingElementParent,
            overElementParent,
        });

        const isElementBetweenStack =
            isElementBetweenStackCheck({
                type,
                draggingElementParent,
                overElementParent,
                isTheSameParent,
                overElement,
            });

        const isContainerAcceptable =
            (template &&
                overElement.acceptedTypes?.includes(
                    template.type,
                )) ||
            (!isTheSameParent &&
                overElementParent?.isContainer === true &&
                template &&
                overElementParent.acceptedTypes?.includes(
                    template?.type,
                )) ||
            isTheSameParent ||
            isElementBetweenStack;

        const isAcceptable = checkIsAcceptable(
            overElement,
            type,
            isMain,
            isContainer,
        );

        if (
            !isContainerAcceptable &&
            isContainerAcceptable !== undefined &&
            !isAcceptable
        ) {
            console.log('NOT ACCEPTED');
            return;
        }

        const isCustomPlacingAcceptable =
            checkIsCustomPlacingAcceptable(
                coreParent,
                getCurrentDataElement,
                type,
            );

        if (!e?.collisions?.length && parentId) {
            handleRemoveElement({
                what: id,
                parent: parentId,
            });

            console.log('removed');

            return;
        }

        const isExistingInsideCurrentContainer =
            isExistingInsideCurrentContainerCheck({
                isMain,
                type,
                overElement,
                draggingElement,
            });

        const movingElementCondition =
            (id &&
                overId &&
                parentId &&
                !isElementBetweenStack) ||
            (isElementBetweenStack &&
                overElementParent &&
                isContainer);

        const updateElementCondition =
            !isTheSameParent &&
            !isExistingInsideCurrentContainer;

        if (movingElementCondition) {
            handleMoveElement({
                what: id,
                where: overId,
                parent: parentId,
            });

            if (updateElementCondition) {
                console.log('update condition');
                handleUpdateElement({
                    parentId: overElement.id,
                    id: draggingElement.id,
                });

                console.log(
                    'element with id:',
                    draggingElement.id,
                    'changed parentId from',
                    draggingElement.parentId,
                    'to',
                    overElement.id,
                );

                console.log('updating');
            }

            console.log('moving');

            return;
        }

        if (!overId || !template) return;

        const isParentMain = isParentMainCheck({
            isElementBetweenStack,
            overElement,
            getCurrentDataElement,
        });

        const customPlacingCondition =
            !isContainer &&
            isCustomPlacingAcceptable &&
            !isMain &&
            !isElementBetweenStack;

        const simplePlacingCondition =
            (isAcceptable &&
                !isMain &&
                !isExistingInsideCurrentContainer) ||
            isMain;

        const betweenStackCustomPlacingCondition =
            isElementBetweenStack &&
            !isParentMain &&
            !isTheSameParent &&
            overElementParent;

        if (customPlacingCondition) {
            console.log('custom');
            handleCreateElementAtCustomPosition(
                template,
                coreParent,
                meta,
                overId,
            );
        } else if (betweenStackCustomPlacingCondition) {
            console.log('between');

            putElementAtCustomPosition({
                handleCreateElementAtCustomPosition,
                handleUpdateElement,
                handleRemoveElement,
                template,
                overElementParent,
                overId,
                draggingElement,
            });
        } else if (
            simplePlacingCondition &&
            !isElementBetweenStack
        ) {
            console.log('simple');
            handleCreateElement(
                template,
                overId.toString(),
                meta,
            );
        }
    };

    const handleDragStart = (e: DragStartEvent) => {
        const data = e.active.data.current;

        if (data) {
            const wrapper = document.getElementById(
                'smart_grid__wrapper',
            );
            const { id } = data;
            setActiveId(id);
            setOverId(activeId);

            setActivePlugin(() => ({
                type: e.active.data.current?.type,
                meta: e.active.data.current?.meta,
            }));

            wrapper?.style.setProperty(
                'cursor',
                'grabbing',
            );
        }
    };

    const handleDragOver = (e: DragOverEvent) => {
        const { over, active } = e;
        setOverId(over?.id ?? null);
    };

    const handleDragCancel = () => {
        resetState();
    };

    function resetState() {
        setOverId(null);
        setActiveId(null);
        const wrapper = document.getElementById(
            'smart_grid__wrapper',
        );
        wrapper?.style.setProperty('cursor', '');
    }

    return (
        <DndContext
            sensors={sensors}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
            onDragCancel={handleDragCancel}
            onDragOver={handleDragOver}
            collisionDetection={pointerWithin}
            // onDragMove={handleDragMove}
        >
            <div
                className={s.wrapper}
                id={'smart_grid__wrapper'}
            >
                <div className={s.children}>
                    <GridView />
                </div>
                <div
                    className={s.settings}
                    id={'grid__settings'}
                >
                    <GridTools />
                </div>
            </div>

            {createPortal(
                <DragOverlay>
                    <div className={s.overlayWrapper}>
                        {activeId ? (
                            <div
                                className={s.viewOverlay({
                                    isOver: !!overId,
                                })}
                            >
                                <RenderView
                                    currentId={
                                        activeId as string
                                    }
                                    parentId={null}
                                    data={
                                        smartGridEditor.data
                                    }
                                />
                            </div>
                        ) : (
                            activePlugin && (
                                <div>
                                    <OverlayPreview
                                        {...activePlugin}
                                    />
                                </div>
                            )
                        )}
                    </div>
                </DragOverlay>,
                document.body,
            )}
        </DndContext>
    );
};
